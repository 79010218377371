<template>
    <el-main>
        <div style="margin-bottom: 15px">
            <el-button class="el-icon-plus" type="primary" size="small"
                @click="$router.push('/extension/gatherTweeters/addEnterprise')">添加企业</el-button>
            <el-button class="el-icon-plus" type="primary" size="small"
                @click="$router.push('/extension/gatherTweeters/businessCate')">添加经营分类</el-button>
        </div>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="入驻类型：">
                <el-select v-model="searchForm.type" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="个体工商户" :value="1"></el-option>
                    <el-option label="企业" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请时间：">
                <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="企业名称：">
                <el-input v-model="searchForm.company_name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="姓名：">
                <el-input v-model="searchForm.name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
                <el-input v-model="searchForm.mobile" size="small"></el-input>
            </el-form-item>
            <el-form-item label="审核状态：">
                <el-select v-model="searchForm.status" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="审核中" :value="-1"></el-option>
                    <el-option label="已通过" :value="1"></el-option>
                    <el-option label="已驳回" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入驻方式：">
                <el-select v-model="searchForm.settled_type" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="申请入驻" :value="1"></el-option>
                    <el-option label="后台添加" :value="2"></el-option>
                    <el-option label="商家转企业" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="排序">
                <template v-slot="{ row }">
                    <el-input v-model="row.sort" class="sortInput" @blur="() => blurChangeSort(row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="入驻类型" align="center">
                <template v-slot="{ row }">
                    {{ row.type == 1 ? '个体工商户入驻' : '企业入驻' }}
                </template>
            </el-table-column>
            <el-table-column prop="company_name" label="企业名称" align="center" width="200">
                <template v-slot="{ row }">
                    {{ row.company_name || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="shop_name" label="店铺名称" align="center" width="200">
                <template v-slot="{ row }">
                    {{ row.shop_name || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="name" label="法人/个人姓名" align="center" width="120"></el-table-column>
            <el-table-column prop="id_number" label="身份证号码" align="center" width="180"></el-table-column>
            <el-table-column prop="mobile" label="联系电话" align="center" width="150"></el-table-column>
            <el-table-column prop="area_name" label="所属区域" align="center" width="100"></el-table-column>
            <el-table-column prop="create_time" label="申请时间" align="center" width="180">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="settled_type" label="入驻方式" align="center" width="120">
                <template v-slot="{ row }">{{ row.settled_type == 1 ? '申请入驻' : row.settled_type == 2 ? '后台添加' : '商家转企业'
                }}</template>
            </el-table-column>
            <el-table-column prop="status" label="审核状态" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" v-if="row.status === -1" style="color:#fdcb8c">审核中</el-button>
                    <el-button type="text" v-if="row.status === 1">已通过</el-button>
                    <el-button type="text" v-if="row.status === 2" style="color:#ff7978">已驳回</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template v-slot="{ row }">
                    <!-- 点击查看申请详情 -->
                    <el-button type="text" size="small" @click="seeApply(row)">查看申请</el-button>
                    <!-- 点击开启审核弹窗 -->
                    <el-button v-if="row.status == -1" type="text" size="small" @click="review(row)">审核</el-button>
                    <!-- 点击显示驳回原因 -->
                    <el-button v-if="row.status == 2" type="text" size="small"
                        @click="(showRemark = !0), (remark = row.remark)">驳回原因</el-button>
                    <el-button type="text" style="color:#ff7978" @click="toGoodsList(row)">案例商品</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="审核" :visible.sync="showReview" width="600px">
            <el-form label-width="90px">
                <el-form-item label="审核结果：">
                    <el-radio-group v-model="status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="驳回原因：" v-if="status == 2">
                    <el-input type="textarea" :rows="4" v-model="remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showReview = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmReview">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="showRemark" width="600px">
            {{ remark }}
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showRemark = !1">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="查看申请" :visible.sync="showInfo" width="650px">
            <el-tabs v-model="activeName">
                <el-tab-pane label="企业/个体户信息" name="1">
                    <el-form label-width="120px" class="apply-info">
                        <el-form-item label="入驻类型：">{{ currentInfo.type == 1 ? '个体工商户入驻' : '企业入驻' }}</el-form-item>
                        <el-form-item label="企业名称：" v-if="currentInfo.type == 2">{{
                            currentInfo.company_name
                        }}</el-form-item>
                        <el-form-item label="法人/个体姓名：">{{ currentInfo.name }}</el-form-item>
                        <el-form-item label="身份证号码：">{{ currentInfo.id_number }}</el-form-item>
                        <el-form-item label="联系电话：">{{ currentInfo.mobile }}</el-form-item>
                        <el-form-item label="邮箱信息：">{{ currentInfo.email }}</el-form-item>
                        <el-form-item label="开户名称：">{{ currentInfo.bank_name }}</el-form-item>
                        <el-form-item label="银行卡账号：">{{ currentInfo.bank_card }}</el-form-item>
                        <el-form-item label="开户银行：">{{ currentInfo.bank_info }}</el-form-item>
                        <el-form-item label="开户银行省市：">{{ currentInfo.bank_province_name }}{{
                            currentInfo.bank_city_name
                        }}</el-form-item>
                        <el-form-item label="身份证正反面：">
                            <el-image style="width: 120px; height: 70px; margin-right: 10px"
                                :src="currentInfo.identity_card_front" fit="cover"
                                :preview-src-list="[currentInfo.identity_card_front, currentInfo.identity_card_back]"></el-image>
                            <el-image style="width: 120px; height: 70px" :src="currentInfo.identity_card_back" fit="cover"
                                :preview-src-list="[currentInfo.identity_card_front, currentInfo.identity_card_back]"></el-image>
                        </el-form-item>
                        <el-form-item label="签合同照片：" v-if="currentInfo.contract_picture">
                            <el-image style="width: 120px; height: 70px; margin-right: 10px" :src="item" fit="cover"
                                v-for="(item, index) in JSON.parse(currentInfo.contract_picture)" :key="index"
                                :preview-src-list="[JSON.parse(currentInfo.contract_picture)]"></el-image>
                        </el-form-item>
                        <el-form-item label="营业执照照片：">
                            <el-image style="width: 120px; height: 70px" :src="currentInfo.business_license" fit="cover"
                                :preview-src-list="[currentInfo.business_license]"></el-image>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="店铺信息" name="2">
                    <el-form label-width="90px" class="apply-info">
                        <el-form-item label="店铺名称：">{{ currentInfo.shop_name }}</el-form-item>
                        <el-form-item label="店铺logo：">
                            <el-image style="width: 120px; height: 70px" :src="currentInfo.shop_logo" fit="cover"
                                :preview-src-list="[currentInfo.shop_logo]"></el-image>
                        </el-form-item>
                        <el-form-item label="所属区域：">{{ currentInfo.city_name }}</el-form-item>
                        <el-form-item label="店铺地址：">{{ currentInfo.shop_address }}</el-form-item>
                        <el-form-item label="点餐版本：">{{ currentInfo.is_new_version ? '新版本' : '旧版本' }}</el-form-item>
                        <el-form-item label="经营类目：">{{ currentInfo.category_name }}</el-form-item>
                        <el-form-item label="相关资质证件：" v-if="currentInfo.qualification">
                            <el-image style="width: 120px; height: 70px; margin-right: 10px" :src="item" fit="cover"
                                v-for="(item, index) in JSON.parse(currentInfo.qualification)" :key="index"
                                :preview-src-list="[JSON.parse(currentInfo.qualification)]"></el-image>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                type: '',
                start_time: '',
                end_time: '',
                company_name: '',
                name: '',
                mobile: '',
                status: '',
                settled_type: ''
            },
            activeName: '1',
            total_number: 0,
            list: [],
            showReview: !1,
            showRemark: !1,
            showInfo: !1,
            status: 1,
            remark: '',
            id: '',
            currentInfo: {},
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                type: '',
                start_time: '',
                end_time: '',
                company_name: '',
                name: '',
                mobile: '',
                status: '',
                settled_type: ''
            };
            this.getList();
        },
        seeApply (row) {
            this.currentInfo = row;
            this.showInfo = !0;
        },
        review (row) {
            this.status = 1;
            this.remark = '';
            this.id = row.id;
            this.showReview = !0;
        },
        comfirmReview () {
            let data = {
                id: this.id,
                status: this.status,
            };
            if (this.status == 2) {
                if (!this.remark) return this.$message.warning('请填写驳回原因');
                data.remark = this.remark;
            }
            this.$axios.post(this.$api.serviceProvider.enterprise.settlementAudit, data).then(res => {
                if (res.code == 0) {
                    this.getList();
                    this.showReview = !1;
                    this.$message.success('处理成功');
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.serviceProvider.enterprise.settlementList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 跳转到案例商品列表
        toGoodsList (data) {
            this.$router.push({
                path: `/extension/gatherTweeters/caseGoodsList?c_name=${data.company_name}&c_id=${data.id}&name=${data.name}`,
            })
        },
        // 失焦触发更改排序
        blurChangeSort (data) {
            this.$axios.post(this.$api.serviceProvider.upSort, {
                id: data.id,
                sort: data.sort
            }).then(res => {
                if (res.code == 0) {
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .sortInput {
        border: none;

        /deep/ input {
            border: none;
            text-align: center;
        }
    }
}

.apply-info {
    .el-form-item {
        margin: 0;
    }
}
</style>
